import { createContext, useState } from "react"

const GlobalContext = createContext()

export const GlobalContextProvider = ({ children }) => {
    const [widgetActive, setWidgetActive] = useState(false)
    const [currentAccount, setCurrentAccount] = useState("")
    const [isWalletConnected, setIsWalletConnected] = useState(false)
    const [isWrongNetwork, setIsWrongNetwork] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)
    const [showGetBnb, setShowGetBnb] = useState(false)
    const [bnbBalance, setBnbBalance] = useState("0")
    const [totalReturn, setTotalReturn] = useState("Loading")
    const [sendModal, setSendModal] = useState(false)
    const [sendConfirmModal, setSendConfirmModal] = useState(false)
    const [currentBnbPrice, setCurrentBnbPrice] = useState("0")
    const [currentSafeGasPrice, setCurrentSafeGasPrice] = useState(null)
    const [magicProvider, setMagicProvider] = useState(null)
    const [loading, setLoading] = useState(false)
    const [walletType, setWalletType] = useState("")
    const [receiveModal, setRecevieModal] = useState(false)
    const [totalUserBalanceInDollar, setTotalUserBalanceInDollar] = useState(0)
    const [top5GraphData, setTop5GraphData] = useState({ week: [], month: [] })
    const [top5MGraphData, setTop5MGraphData] = useState({ week: [], month: [] })
    const [top10GraphData, setTop10GraphData] = useState({ week: [], month: [] })
    const [top10MGraphData, setTop10MGraphData] = useState({ week: [], month: [] })
    const [venusGraphData, setVenusGraphData] = useState({ week: [], month: [] })
    const [vPFFWGraphData, setVPFFWGraphData] = useState({ week: [], month: [] })
    const [BIGCAPGraphData, setBIGCAPGraphData] = useState({ week: [], month: [] })
    const [DeFiGemsGraphData, setDeFiGemsGraphData] = useState({ week: [], month: [] })
    const [StablecoinYieldGraphData, setStablecoinYieldGraphData] = useState({
        week: [],
        month: [],
    })
    const [ChatGptGraphData, setChatGptGraphData] = useState({
        week: [],
        month: [],
    })
    const [SafeGraphData, setSafeGraphData] = useState({
        week: [],
        month: [],
    })
    const [W3GraphData, setW3GraphData] = useState({
        week: [],
        month: [],
    })
    const [apy, setApy] = useState({})
    const [top5Return, setTop5Return] = useState({ day: 0, week: 0, month: 0 })
    const [top5MReturn, setTop5MReturn] = useState({ day: 0, week: 0, month: 0 })
    const [top10Return, setTop10Return] = useState({ day: 0, week: 0, month: 0 })
    const [top10MReturn, setTop10MReturn] = useState({ day: 0, week: 0, month: 0 })
    const [venusReturn, setVenusReturn] = useState({ day: 0, week: 0, month: 0 })
    const [vPFFWReturn, setVPFFWReturn] = useState({ day: 0, week: 0, month: 0 })
    const [bigCapReturn, setBigCapReturn] = useState({ day: 0, week: 0, month: 0 })
    const [DeFiGemsReturn, setDeFiGemsReturn] = useState({ day: 0, week: 0, month: 0 })
    const [StablecoinYieldReturn, setStablecoinYieldReturn] = useState({
        day: 0,
        week: 0,
        month: 0,
    })
    const [ChatGptReturn, setChatGptReturn] = useState({
        day: 0,
        week: 0,
        month: 0,
    })
    const [SafeReturn, setSafeReturn] = useState({
        day: 0,
        week: 0,
        month: 0,
    })
    const [W3Return, setW3Return] = useState({
        day: 0,
        week: 0,
        month: 0,
    })

    const [top5LiveGraphData, setTop5LiveGraphData] = useState([])
    const [top5MLiveGraphData, setTop5MLiveGraphData] = useState([])
    const [top10LiveGraphData, setTop10LiveGraphData] = useState([])
    const [top10MLiveGraphData, setTop10MLiveGraphData] = useState([])
    const [venusLiveGraphData, setVenusLiveGraphData] = useState([])
    const [vPFFWLiveGraphData, setVPFFWLiveGraphData] = useState([])
    const [BIGCAPLiveGraphData, setBIGCAPLiveGraphData] = useState([])
    const [DeFiGemsLiveGraphData, setDeFiGemsLiveGraphData] = useState([])
    const [StablecoinYieldLiveGraphData, setStablecoinYieldLiveGraphData] = useState([])
    const [ChatGptLiveGraphData, setChatGptLiveGraphData] = useState([])
    const [SafeLiveGraphData, setSafeLiveGraphData] = useState([])
    const [W3LiveGraphData, setW3LiveGraphData] = useState([])

    const [userIndexTokensBalance, setUserIndexTokensBalance] = useState({
        TOP5: "0",
        META: "0",
        VTOP10: "0",
        TOP10: "0",
        TOP5MCAP: "0",
        TOP10MCAP: "0",
        VPFFW: "0",
        ChatGpt: "0",
        Safe: "0",
        W3: "0",
    })

    const [indexTokensRate, setIndexTokensRate] = useState({
        TOP5: 0,
        META: 0,
        VTOP10: 0,
        TOP10: 0,
        TOP5MCAP: 0,
        TOP10MCAP: 0,
        VPFFW: 0,
        ChatGpt: 0,
        Safe: 0,
        W3: 0,
    })

    const [indexesVaultBalance, setIndexesVaultBalance] = useState({
        TOP5: "0",
        META: "0",
        VTOP10: "0",
        TOP10: "0",
        TOP5MCAP: "0",
        TOP10MCAP: "0",
        BIGCAP: "0",
        VPFFW: "0",
        DeFiGems: "0",
        StablecoinYield: "0",
        ChatGpt: "0",
        Safe: "0",
        W3: "0",
    })
    const [indexTokensTotalSupply, setIndexTokensTotalSupply] = useState({
        TOP5: "0",
        META: "0",
        VTOP10: "0",
        TOP10: "0",
        TOP5MCAP: "0",
        TOP10MCAP: "0",
        BIGCAP: "0",
        VPFFW: "0",
        DeFiGems: "0",
        StablecoinYield: "0",
        ChatGpt: "0",
        Safe: "0",
        W3: "0",
    })
    const [top5Tokens, setTop5Tokens] = useState(null)
    const [top5MCapTokens, setTop5MCapTokens] = useState(null)
    const [top10MCapTokens, setTop10MCapTokens] = useState(null)
    const [vpffwTokens, setVpffwTokens] = useState(null)
    const [vtop10Tokens, setVtop10Tokens] = useState(null)
    const [top10Tokens, setTop10Tokens] = useState(null)
    const [BIGCAPTokens, setBIGCAPTokens] = useState(null)
    const [DeFiGemsTokens, setDeFiGemsTokens] = useState(null)
    const [StablecoinYieldTokens, setStablecoinYieldTokens] = useState(null)
    const [ChatGptTokens, setChatGptTokens] = useState(null)
    const [SafeTokens, setSafeTokens] = useState(null)
    const [W3Tokens, setW3Tokens] = useState(null)

    const [pieChartData, setPieChartData] = useState([])
    const [pieChartDataLoading, setPieChartDataLoading] = useState(true)

    const [portfolioBoxLoading, setPortfolioBoxLoading] = useState(false)
    const [userBalanceLoading, setUserBalanceLoading] = useState(false)
    const [overalLoading, setOvervalLoading] = useState(true)

    const [top5ReturnRate, setTop5ReturnRate] = useState(0)
    const [top5MCapReturnRate, setTop5MCapReturnRate] = useState(0)
    const [top10MCapReturnRate, setTop10MCapReturnRate] = useState(0)
    const [vpffwReturnRate, setVpffwReturnRate] = useState(0)
    const [BIGCAPReturnRate, setBIGCAPReturnRate] = useState(0)
    const [DeFiGemsReturnRate, setDeFiGemsReturnRate] = useState(0)
    const [StablecoinYieldReturnRate, setStablecoinYieldReturnRate] = useState(0)
    const [ChatGptReturnRate, setChatGptReturnRate] = useState(0)
    const [SafeReturnRate, setSafeReturnRate] = useState(0)
    const [W3ReturnRate, setW3ReturnRate] = useState(0)
    const [venus10ReturnRate, setVenus10ReturnRate] = useState(0)
    const [top10Data, setTop10Data] = useState(0)
    const [portfolio, setPortfolio] = useState("All")
    const [totalOfInvestment, setTotalOfInvestment] = useState("Loading")

    const states = {
        top5LiveGraphData,
        setTop5LiveGraphData,
        top5MLiveGraphData,
        setTop5MLiveGraphData,
        top10LiveGraphData,
        setTop10LiveGraphData,
        top10MLiveGraphData,
        setTop10MLiveGraphData,
        venusLiveGraphData,
        setVenusLiveGraphData,
        vPFFWLiveGraphData,
        setVPFFWLiveGraphData,
        BIGCAPLiveGraphData,
        setBIGCAPLiveGraphData,
        DeFiGemsLiveGraphData,
        setDeFiGemsLiveGraphData,
        StablecoinYieldLiveGraphData,
        setStablecoinYieldLiveGraphData,
        overalLoading,
        setOvervalLoading,
        pieChartDataLoading,
        setPieChartDataLoading,
        portfolioBoxLoading,
        setPortfolioBoxLoading,
        currentAccount,
        setCurrentAccount,
        isWalletConnected,
        setIsWalletConnected,
        isWrongNetwork,
        setIsWrongNetwork,
        magicProvider,
        setMagicProvider,
        showConnectWalletModal,
        setShowConnectWalletModal,
        currentBnbPrice,
        setCurrentBnbPrice,
        currentSafeGasPrice,
        setCurrentSafeGasPrice,
        bnbBalance,
        setBnbBalance,
        userIndexTokensBalance,
        setUserIndexTokensBalance,
        indexTokensRate,
        setIndexTokensRate,
        totalUserBalanceInDollar,
        setTotalUserBalanceInDollar,
        showGetBnb,
        setShowGetBnb,
        setWalletType,
        walletType,
        setTotalReturn,
        totalReturn,
        setLoading,
        loading,
        setSendModal,
        sendModal,
        sendConfirmModal,
        setSendConfirmModal,
        setRecevieModal,
        receiveModal,
        top5GraphData,
        setTop5GraphData,
        top5MGraphData,
        setTop5MGraphData,
        top10GraphData,
        setTop10GraphData,
        top10MGraphData,
        setTop10MGraphData,
        venusGraphData,
        setVenusGraphData,
        vPFFWGraphData,
        setVPFFWGraphData,
        BIGCAPGraphData,
        setBIGCAPGraphData,
        top5Return,
        setTop5Return,
        top5MReturn,
        setTop5MReturn,
        top10Return,
        setTop10Return,
        top10MReturn,
        setTop10MReturn,
        venusReturn,
        setVenusReturn,
        vPFFWReturn,
        setVPFFWReturn,
        bigCapReturn,
        setBigCapReturn,
        DeFiGemsReturn,
        setDeFiGemsReturn,
        StablecoinYieldReturn,
        setStablecoinYieldReturn,
        DeFiGemsGraphData,
        setDeFiGemsGraphData,
        StablecoinYieldGraphData,
        setStablecoinYieldGraphData,
        setIsActive,
        isActive,
        indexesVaultBalance,
        setIndexesVaultBalance,
        indexTokensTotalSupply,
        setIndexTokensTotalSupply,
        pieChartData,
        setPieChartData,
        apy,
        setApy,
        top5Tokens,
        setTop5Tokens,
        top5MCapTokens,
        setTop5MCapTokens,
        top10MCapTokens,
        setTop10MCapTokens,
        vpffwTokens,
        setVpffwTokens,
        vtop10Tokens,
        setVtop10Tokens,
        top10Tokens,
        setTop10Tokens,
        BIGCAPTokens,
        setBIGCAPTokens,
        DeFiGemsTokens,
        setDeFiGemsTokens,
        StablecoinYieldTokens,
        setStablecoinYieldTokens,
        userBalanceLoading,
        setUserBalanceLoading,
        top5ReturnRate,
        setTop5ReturnRate,
        top5MCapReturnRate,
        setTop5MCapReturnRate,
        top10MCapReturnRate,
        setTop10MCapReturnRate,
        vpffwReturnRate,
        setVpffwReturnRate,
        BIGCAPReturnRate,
        setBIGCAPReturnRate,
        DeFiGemsReturnRate,
        setDeFiGemsReturnRate,
        StablecoinYieldReturnRate,
        setStablecoinYieldReturnRate,
        venus10ReturnRate,
        setVenus10ReturnRate,
        top10Data,
        setTop10Data,
        portfolio,
        setPortfolio,
        ChatGptGraphData,
        setChatGptGraphData,
        ChatGptReturn,
        setChatGptReturn,
        ChatGptLiveGraphData,
        setChatGptLiveGraphData,
        ChatGptTokens,
        setChatGptTokens,
        ChatGptReturnRate,
        setChatGptReturnRate,
        SafeReturn,
        setSafeReturn,
        SafeGraphData,
        setSafeGraphData,
        SafeLiveGraphData,
        setSafeLiveGraphData,
        SafeTokens,
        setSafeTokens,
        SafeReturnRate,
        setSafeReturnRate,
        totalOfInvestment,
        setTotalOfInvestment,
        W3GraphData,
        setW3GraphData,
        W3Return,
        setW3Return,
        W3LiveGraphData,
        setW3LiveGraphData,
        W3Tokens,
        setW3Tokens,
        W3ReturnRate,
        setW3ReturnRate,
        widgetActive,
        setWidgetActive,
    }

    return <GlobalContext.Provider value={states}>{children}</GlobalContext.Provider>
}

export default GlobalContext
