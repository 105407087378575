/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    address: "",
    isConnected: false,
    loading: false,
    error: null,
    networkId: null,
    chainId: null,
    walletType: null,
    walletName: "",
    balance: 0,
    displayAddress: "",
    displayAddressMobile: "",
    projectContract: {},
    isProfileInfoLoading: false,
    profileInfo: null,
    isProfileInfoError: null,
}

function formatAddress(address) {
    return `${address.substring(0, 5)}...${address.substring(address.length - 4, address.length)}`
}
function formatAddressMobile(address) {
    return `...${address.substring(address.length - 3, address.length)}`
}

export const userSlice = createSlice({
    name: "user",
    initialState,

    reducers: {
        setAccountDetails: (state, action) => {
            return {
                ...state,
                address: action.payload.address,
                isConnected: true,
                networkId: action.payload.networkId,
                chainId: action.payload.chainId,
                walletName: action.payload.walletName,
                walletType: action.payload.walletType,
                balance: action.payload.balance,
                displayAddress: formatAddress(action.payload.address),
                displayAddressMobile: formatAddressMobile(action.payload.address),
            }
        },

        updateAccountDetails: (state, action) => {
            return {
                ...state,
                address: action.payload.address,
                isConnected: true,
                networkId: action.payload.networkId,
                chainId: action.payload.chainId,
                balance: action.payload.balance,
                displayAddress: formatAddress(action.payload.address),
                displayAddressMobile: formatAddressMobile(action.payload.address),
            }
        },

        setUserAddress: (state, action) => {
            return {
                ...state,
                address: action.payload,
                displayAddress: formatAddress(action.payload),
                displayAddressMobile: formatAddressMobile(action.payload),
            }
        },

        setUserNetwork: (state, action) => {
            return {
                ...state,
                networkId: parseFloat(action.payload),
            }
        },

        setUserBalance: (state, action) => {
            return {
                ...state,
                balance: parseFloat(action.payload),
            }
        },

        walletDisconnect: (state) => {
            return {
                ...state,
                address: "",
                isConnected: false,
                loading: false,
                error: null,
                networkId: null,
                chainId: null,
                walletType: null,
                walletName: "",
                balance: 0,
                displayAddress: "",
                displayAddressMobile: "",
            }
        },

        setProjectContract: (state, action) => {
            return {
                ...state,
                projectContract: action.payload,
            }
        },

        resetProjectContract: (state) => {
            return {
                ...state,
                projectContract: {},
            }
        },
    },
})

export const {
    setAccountDetails,
    setUserAddress,
    setUserNetwork,
    setUserBalance,
    walletDisconnect,
    setProjectContract,
    resetProjectContract,
    updateAccountDetails,
} = userSlice.actions

export default userSlice.reducer
