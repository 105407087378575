import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import reportWebVitals from "./reportWebVitals"
import { HashRouter } from "react-router-dom"
import "./styles/index.css"

import App from "./App"

import store from "./store"

import { GlobalContextProvider } from "./context/GlobalContext/GlobalContext"
import { CreateModalProvider } from "./context/CreateModal/CreateModalContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
const persistor = persistStore(store)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <GlobalContextProvider>
                    <CreateModalProvider>
                        <HashRouter>
                            <App />
                        </HashRouter>
                    </CreateModalProvider>
                </GlobalContextProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
)

reportWebVitals()
