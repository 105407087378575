import React, { useEffect, useContext } from "react"
import "react-toastify/dist/ReactToastify.css"
import "./styles/App.css"
import { Routes, Route } from "react-router-dom"

// components
// import Layout from "./components/Layout/Layout"

// context
import GlobalContext from "./context/GlobalContext/GlobalContext"

// pages
// import Home from "./pages/Home/Home"
const Home = React.lazy(() => import("./pages/Home/Home"))
function App() {
    const { setCurrentBnbPrice } = useContext(GlobalContext)

    useEffect(() => {
        // fetching bnb price in BUSD
        fetch("https://api.binance.com/api/v3/ticker/price?symbol=BNBBUSD")
            .then((res) => res.json())
            .then((data) => {
                const price = data.price
                setCurrentBnbPrice(price)
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        // <Layout>
        <Routes>
            <Route path="/*" element={<Home />} />
            {/* <Route path="/swap/*" element={<WidgetPage />} /> */}
            {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
        // </Layout>
    )
}

export default App
